import React from "react"
import {  Image,Row, Col } from "react-bootstrap"

const VehiclesScreen = () => {
  return (
    <>

      <Row className='justify-content-md-center'>
          <Col sm={12} md={12} lg={8} xl={8}>
            <Image src="./images/loscabos_transportations_services.jpg" fluid />                
            <Image src="./images/loscabos_transportation_limousine.jpg" fluid />                            
            <Image src="./images/loscabos_transportation_suburban.jpg" fluid />                                        
            <Image src="./images/SHUTTLE_TRANSPORTATION_loscabos.jpg" fluid />                              
            <Image src="./images/BUS_TRANSPORTATION_loscabos.jpg" fluid />                                                  
          </Col>
      </Row>

    </>
  )
}

export default VehiclesScreen
