import React, {useState,useEffect} from 'react'
import { Col, Form } from "react-bootstrap"
import DepartureAirlines from '../components/DepartureAirlines'
import ShuttleItineraryAlert from "../components/ShuttleItineraryAlert";
import ShuttleItineraries from "../components/ShuttleItineraries";
import moment from 'moment';

function DepartureTrip({selectedDepartureAirline,departureData,setDepartureData,isHotelItineraries,
  hotelId,setIsDifferenceOK,isDifferenceOK}) {
  
  const [departureAirline,setDepartureAirline]=useState()

  const [firstShuttleHour,setFirstShuttleHour]=useState(" ")

  
  
  function setTimeDifference(departureTime, shuttleHour,isHotelItineraries){
    let difference=moment(departureTime,"HH:mm:ss").diff(moment(shuttleHour,"HH:mm:ss"), 'minutes')
    if (isHotelItineraries!==''){
      if (difference>=180){
        return true
      }
      else{
        return false
      }      
    }
    else{
      return true
    }
  }

  useEffect(() => {
    selectedDepartureAirline(departureAirline)
    departureData.shuttleHour=firstShuttleHour
  },[firstShuttleHour,departureAirline]);

    return (
        <div>
   <Form.Group controlId="formGroupDeparture">
    <Form.Row  className="justify-content-center m-2">
    <Col lg={4} className="m-1">
    <DepartureAirlines value={departureAirline}
          onChange={ event => {
            setDepartureAirline(event.target.value)
            }
            } />    
      </Col>
      </Form.Row>

      <Form.Row  className="justify-content-center m-2">
        <Col lg={4} className="m-1">
          <Form.Label>Departure Flight Number</Form.Label>
          <Form.Control name="flight" type="text" maxLength="10" placeholder="Number Of Flight" value={departureData.flight} 
              onChange={e => {
                setDepartureData({
                ...departureData,
                [e.target.name]: e.target.value})
              }} required />
        </Col>
      </Form.Row>

      <Form.Row  className="justify-content-center m-2">
        <Col lg={4} className="m-1">
          <Form.Label>Departure Date</Form.Label>
          <Form.Control name="date" type="date"  value={departureData.date} 
              onChange={e => {
                setDepartureData({
                ...departureData,
                [e.target.name]: e.target.value})
              }} required min={moment().format("YYYY-MM-DD")} />
        </Col>
      </Form.Row>

      <Form.Row  className="justify-content-center m-2">
        <Col lg={4} className="m-1">
          <Form.Label>Departure Time</Form.Label>
          <Form.Control name="time" type="time" value={departureData.time} 

            onChange={e => {
              setDepartureData({
              ...departureData,
              [e.target.name]: e.target.value})
              setIsDifferenceOK(setTimeDifference(e.target.value,departureData.shuttleHour,isHotelItineraries))
            }
            } 

            required />
      </Col>
      </Form.Row>

       <Form.Row  className="justify-content-center m-2">
          <Col lg={4} className="m-1">
              {!isDifferenceOK && isHotelItineraries &&  <ShuttleItineraryAlert/>} 
          </Col>
      </Form.Row>

      <Form.Row  className="justify-content-center m-2">
        <Col lg={4} className="m-1">
          {isHotelItineraries &&  <ShuttleItineraries hotelId={hotelId} value={departureData.shuttleHour} 
          setFirstShuttleHour={setFirstShuttleHour} onChange={ e => {
            setDepartureData({
              ...departureData,
              [e.target.name]: e.target.value})
              setIsDifferenceOK(setTimeDifference(departureData.time,e.target.value,isHotelItineraries))                        
            }
            }/>} 

      </Col>
      </Form.Row>

    </Form.Group>     
    
        </div>
    )
}

export default DepartureTrip
