import React, { useState, useEffect } from "react"
import { Card, Form } from "react-bootstrap"
import axios from "axios"
import moment from "moment"

const ShuttleItineraries = ({
  value,
  onChange,
  hotelId,
  setFirstShuttleHour,
}) => {
  const [itineraries, setItineraries] = useState([])

  const server = "https://transportation-backend-test.link"

  const fetchItineraries = async () => {
    const { data } = await axios.get(`${server}/shuttle-itineraries/${hotelId}`)
    //data.unshift(" ")
    setItineraries(data)
    setFirstShuttleHour(data[0].time)
  }

  useEffect(() => {
    fetchItineraries()
  }, [])

  return (
    <div>
      <Card.Text as='h6'>
        <strong>Shuttle Itineraries</strong>
      </Card.Text>
      <Card className='text-center'>
        <Card.Body>
          <Card.Title as='h4'>
            <Form.Control
              as='select'
              name='shuttleHour'
              required
              value={value}
              onChange={onChange}
            >
              {itineraries.map((x, index) => (
                <option key={index} value={x.time}>
                  {moment(x.time, "hh:mm a").format("hh:mm a")}
                </option>
              ))}
            </Form.Control>
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ShuttleItineraries
