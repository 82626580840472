import React, { useState, useEffect } from "react"
import { Radio } from "antd"
import { Link } from "react-router-dom"
import { Card, Button, Modal } from "react-bootstrap"
import GeneralRates from "./GeneralRates"
import axios from "axios"
import GeneralTransportations from "./GeneralTransportations"
import GeneralHotelsItineraries from "./GeneralHotelsItineraries"

const GeneralServices = ({ product, projectId }) => {
  const [hotelRates, setHotelRates] = useState([])
  const [tripPrice, setTripPrice] = useState(0)
  const [tripPriceBool, setTripPriceBool] = useState(false)
  const [numOfPassengers, setNumOfPassengers] = useState(0)
  const [kindOfTrip, setKindOfTrip] = useState("")
  const [chargeByPerson, setChargeByPerson] = useState(false)
  const [hotelName, sethotelName] = useState("")
  const [hotelId, sethotelId] = useState(0)
  const [zoneId, setzoneId] = useState(0)
  const [specialZone, setspecialZone] = useState(0)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [numOfPass, setNumOfPass] = useState([])
  const [transportationId, setTransportationId] = useState([])
  const [transportationNameSelected, setTransportationNameSelected] =
    useState("")
  const [transportationIdSelected, setTransportationIdSelected] = useState(0)
  const [isSelectedHotel, setIsSelectedHotel] = useState(false)
  const [showTransportations, setshowTransportations] = useState(false)
  const [render, setRender] = useState(false)
  const [isHotelItineraries, setIsHotelItineraries] = useState("")

  const server = "https://transportation-backend-test.link"

  const getPricesAPI = (
    transportationIdSelected,
    numofpass,
    kindOfTrip,
    zoneId
  ) => {
    const fetchPrices = async () => {
      const { data } = await axios.get(
        `${server}/services/get-prices-zones/${zoneId}/${transportationIdSelected}/${projectId}`
      )
      if (data.length) {
        let departureprice = data[0].departureprice
        let arrivalprice = data[0].arrivalprice
        let roundprice = data[0].roundprice
        setTripPriceBool(true)

        if (chargeByPerson) {
          if (kindOfTrip === "Departure") {
            setTripPrice(departureprice * numofpass)
          }
          if (kindOfTrip === "Arrival") {
            setTripPrice(arrivalprice * numofpass)
          }
          if (kindOfTrip === "Round") {
            setTripPrice(roundprice * numofpass)
          }
        } else {
          if (kindOfTrip === "Departure") {
            setTripPrice(departureprice)
          }
          if (kindOfTrip === "Arrival") {
            setTripPrice(arrivalprice)
          }
          if (kindOfTrip === "Round") {
            setTripPrice(roundprice)
          }
        }
      } else {
        setTripPrice(0)
        setTripPriceBool(false)
      }
    }

    if (zoneId !== " " && transportationIdSelected !== " ") {
      fetchPrices()
    } else {
      setTripPrice(0)
      setTripPriceBool(false)
    }
  }

  const fetchHotelRates = async (projectId) => {
    const { data } = await axios.get(`${server}/hotels-services/${projectId}`)
    data.unshift({
      id: 0,
      Name: " ",
      zone: " ",
    })

    setHotelRates(data)
  }

  const fetchNumOfPass = async (specialZoneVal, zoneId) => {
    const { data } = await axios.get(
      `${server}/services/num-of-pass/${product._id}/${projectId}/${specialZoneVal}/${zoneId}`
    )

    let numPass = []
    let transpId = []

    for (let i in data) {
      numPass.push(data[i].NumOfPass)
      transpId.push(data[i].GeneralTransportationId)
    }

    transpId.unshift(" ")
    numPass.unshift(" ")
    setNumOfPass(numPass)
    setTransportationId(transpId)

    if (specialZone !== specialZoneVal) {
      setTransportationNameSelected("")
    }
  }

  useEffect(() => {
    fetchHotelRates(projectId)
    fetchNumOfPass(specialZone, zoneId)
  }, [])

  return (
    <div>
      <Card className='text-center'>
        <Card.Img
          src={product.image}
          variant='top'
          width='418px'
          height='279px'
        />
        <Card.Body>
          <Card.Title as='h4'>
            <strong>{product.name}</strong>
          </Card.Title>
          <Card.Text as='h5'>{product.description}</Card.Text>
          <Card.Text as='h3'>${tripPrice}</Card.Text>
          <Radio.Group buttonStyle='solid'>
            <Card.Text as='h6'>
              <strong>Select Trip</strong>
            </Card.Text>

            {product.arrivaltrip && (
              <Radio.Button
                value='Arrival'
                onClick={(e) => {
                  setKindOfTrip("Arrival")
                  setChargeByPerson(product.chargebyperson)
                  isSelectedHotel === false
                    ? handleShow()
                    : getPricesAPI(
                        transportationIdSelected,
                        numOfPassengers,
                        "Arrival",
                        zoneId
                      )
                }}
              >
                Arrival
              </Radio.Button>
            )}

            {product.departuretrip && (
              <Radio.Button
                value='Departure'
                onClick={(e) => {
                  setKindOfTrip("Departure")
                  setChargeByPerson(product.chargebyperson)
                  isSelectedHotel === false
                    ? handleShow()
                    : getPricesAPI(
                        transportationIdSelected,
                        numOfPassengers,
                        "Departure",
                        zoneId
                      )
                }}
              >
                Departure
              </Radio.Button>
            )}

            {product.roundtrip && (
              <Radio.Button
                value='Round'
                onClick={(e) => {
                  setKindOfTrip("Round")
                  setChargeByPerson(product.chargebyperson)
                  isSelectedHotel === false
                    ? handleShow()
                    : getPricesAPI(
                        transportationIdSelected,
                        numOfPassengers,
                        "Round",
                        zoneId
                      )
                }}
              >
                Round
              </Radio.Button>
            )}
          </Radio.Group>

          {isSelectedHotel && (
            <GeneralRates
              hotelRates={hotelRates}
              value={hotelName}
              onChange={(event) => {
                sethotelId(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-hotelid"
                  )
                )
                setzoneId(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-zoneid"
                  )
                )
                setspecialZone(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-specialzone"
                  )
                )
                fetchNumOfPass(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-specialzone"
                  ),
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-zoneid"
                  )
                )
                sethotelName(event.target.value)
                getPricesAPI(
                  transportationIdSelected,
                  numOfPassengers,
                  kindOfTrip,
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-zoneid"
                  )
                )
                setRender({ ...render, value: true })
              }}
            />
          )}

          {kindOfTrip !== "Arrival" &&
            isSelectedHotel &&
            product.name === "Shuttle" && (
              <GeneralHotelsItineraries
                hotelId={hotelId}
                render={render}
                setIsHotelItineraries={setIsHotelItineraries}
                isHotelItineraries={isHotelItineraries}
              />
            )}

          {isSelectedHotel && (
            <GeneralTransportations
              product={product}
              transportationNameSelected={transportationNameSelected}
              transportationId={transportationId}
              numOfPass={numOfPass}
              onChange={(event) => {
                setTransportationNameSelected(event.target.value)
                setTransportationIdSelected(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-transpid"
                  )
                )
                setNumOfPassengers(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-numofpass"
                  )
                )
                getPricesAPI(
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-transpid"
                  ),
                  event.target[event.target.selectedIndex].getAttribute(
                    "data-numofpass"
                  ),
                  kindOfTrip,
                  zoneId
                )
              }}
            />
          )}
          {tripPriceBool && (
            <Link
              to={{
                pathname: `/service/${product._id}`,
                state: {
                  tripPrice: tripPrice,
                  transportationNameSelected: transportationNameSelected,
                  kindOfTrip: kindOfTrip,
                  hotelName: hotelName,
                  hotelId: hotelId,
                  serviceId: product._id,
                  transportationIdSelected: transportationIdSelected,
                  isHotelItineraries,
                },
              }}
            >
              <Button variant='outline-dark' size='md'>
                Buy
              </Button>
            </Link>
          )}
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Footer class='modal-title w-100 text-center'>
          <Card className='text-center'>
            <Card.Body>
              <GeneralRates
                hotelRates={hotelRates}
                value={hotelName}
                onChange={(event) => {
                  sethotelId(
                    event.target[event.target.selectedIndex].getAttribute(
                      "data-hotelid"
                    )
                  )
                  setzoneId(
                    event.target[event.target.selectedIndex].getAttribute(
                      "data-zoneid"
                    )
                  )
                  setspecialZone(
                    event.target[event.target.selectedIndex].getAttribute(
                      "data-specialzone"
                    )
                  )
                  fetchNumOfPass(
                    event.target[event.target.selectedIndex].getAttribute(
                      "data-specialzone"
                    ),
                    event.target[event.target.selectedIndex].getAttribute(
                      "data-zoneid"
                    )
                  )
                  sethotelName(event.target.value)
                  setshowTransportations(true)
                }}
              />

              {showTransportations && (
                <GeneralTransportations
                  product={product}
                  transportationNameSelected={transportationNameSelected}
                  transportationId={transportationId}
                  numOfPass={numOfPass}
                  onChange={(event) => {
                    setTransportationNameSelected(event.target.value)
                    setTransportationIdSelected(
                      event.target[event.target.selectedIndex].getAttribute(
                        "data-transpid"
                      )
                    )
                    setNumOfPassengers(
                      event.target[event.target.selectedIndex].getAttribute(
                        "data-numofpass"
                      )
                    )
                    setIsSelectedHotel(true)
                    getPricesAPI(
                      event.target[event.target.selectedIndex].getAttribute(
                        "data-transpid"
                      ),
                      event.target[event.target.selectedIndex].getAttribute(
                        "data-numofpass"
                      ),
                      kindOfTrip,
                      zoneId
                    )
                    handleClose()
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default GeneralServices
