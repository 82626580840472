import React from "react"
import { Card, Form } from "react-bootstrap"

const GeneralRatesWedding = ({ hotelRates, value, onChange }) => {
  return (
    <div>
      <Card.Text as='h6'><strong>Select Hotel</strong></Card.Text>
    <Card className="text-center">
      <Card.Body>
          <Card.Title as='h4'>
          <Form.Control as="select" required  value={value}
          onChange={onChange}
          >{
            hotelRates.map((x,index)=>(
                <option key={index} data-hotelid={x.id}  value={x.Name} data-zoneid={x.zone}>
                  {x.Name}
                </option>
              ))
            }
          </Form.Control> 
          </Card.Title>
      </Card.Body>
    </Card>
    </div>
  )
}

export default GeneralRatesWedding
