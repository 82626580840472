import React, {useState,useEffect} from 'react'
import { Col, Form } from "react-bootstrap"
import ArrivalAirlines from '../components/ArrivalAirlines'
import moment from "moment"

function ArrivalTrip({selectedArrivalAirline, arrivalData, setArrivalData}) {
  const [arrivalAirline,setArrivalAirline]=useState()

  const updateField = e => {
    setArrivalData({
      ...arrivalData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    selectedArrivalAirline(arrivalAirline)
  });
  
    return (
    <div>
    <Form>
    <Form.Group controlId="formGroupArrival">
      <Form.Row  className="justify-content-center m-2">
      <Col lg={4} className="m-1">
          <ArrivalAirlines value={arrivalAirline}
          onChange={ event => {
            setArrivalAirline(event.target.value)
            }
            } />          
        </Col>
        </Form.Row>

        <Form.Row  className="justify-content-center m-2">
        <Col lg={4} className="m-1">
          <Form.Label>Arrival Flight Number</Form.Label>
          <Form.Control name="flight" type="text" maxLength="10" placeholder="Number Of Flight" value={arrivalData.flight} 
            onChange={updateField} required />
        </Col>
      </Form.Row>

        <Form.Row  className="justify-content-center m-2">
          <Col lg={4} className="m-1">
            <Form.Label>Arrival Date</Form.Label>
            <Form.Control name="date" type="date" value={arrivalData.date} 
            onChange={updateField} required min={moment().format("YYYY-MM-DD")} />
          </Col>
        </Form.Row>        

        <Form.Row  className="justify-content-center m-2">
        <Col lg={4} className="m-1">
          <Form.Label>Arrival Time</Form.Label>
          <Form.Control name="time" type="time" value={arrivalData.time} 
            onChange={updateField} required />
        </Col>
      </Form.Row>

    </Form.Group>
    </Form>
            
        </div>
    )
}

export default ArrivalTrip

