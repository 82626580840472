import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container } from "react-bootstrap"
import Header from "./components/Header"
import FooterWix from "./components/FooterWix"
import GeneralHomeScreen from "./screens/GeneralHomeScreen"
import GeneralHomeScreenProjects from "./screens/GeneralHomeScreenProjects"
import GeneralHomeScreenGroups from "./screens/GeneralHomeScreenGroups"
import GeneralHomeScreenTours from "./screens/GeneralHomeScreenTours"
import GeneralHomeScreenTrips from "./screens/GeneralHomeScreenTrips"
import HomePage from "./screens/HomePage"
import ProductScreen from "./screens/ProductScreen"
import ProductScreenProjects from "./screens/ProductScreenProjects"
import ProductScreenWeddings from "./screens/ProductScreenWeddings"
import ProductScreenGroups from "./screens/ProductScreenGroups"
import ProductScreenTours from "./screens/ProductScreenTours"
import ProductScreenTrips from "./screens/ProductScreenTrips"
import ProductScreenHourly from "./screens/ProductScreenHourly"
import GeneralHomeScreenWeddings from "./screens/GeneralHomeScreenWeddings"
import VehiclesScreen from "./screens/VehiclesScreen"
import TestimonialsScreen from "./screens/TestimonialsScreen"
import LocationScreen from "./screens/LocationScreen"
import ContactUsScreen from "./screens/ContactUsScreen"
import InstructionsScreen from "./screens/InstructionsScreen"
import GeneralHomeScreenHourly from "./screens/GeneralHomeScreenHourly"
import GeneralHomeScreenActivities from "./screens/GeneralHomeScreenActivities"
import GeneralHomeScreenSubActivities from "./screens/GeneralHomeScreenSubActivities"





const App = () => {
  return (
    <Router>
      <Header/>
      <main className='py-3'>
        <Container>
          <Route path='/' component={HomePage} exact />
          <Route path='/service/:id' component={ProductScreen} exact />
          <Route
            path='/services-projects/:id/:projectId'
            component={ProductScreenProjects}
            exact
          />
          <Route
            path='/services-weddings/:id/:weddingId'
            component={ProductScreenWeddings}
            exact
          />
          <Route
            path='/services-groups/:id/:groupId'
            component={ProductScreenGroups}
            exact
          />
          <Route
            path='/services-tours/:id/:tourId'
            component={ProductScreenTours}
            exact
          />
          <Route
            path='/services-trips/:id/:tripId'
            component={ProductScreenTrips}
            exact
          />

          <Route
            path='/services-hourly/:id/:hourlyId'
            component={ProductScreenHourly}
            exact
          />

          <Route path='/transportations' component={GeneralHomeScreen} exact />
          <Route
            path='/transportations-projects/:projectId'
            component={GeneralHomeScreenProjects}
            exact
          />
          <Route
            path='/transportations-weddings/:weddingId'
            component={GeneralHomeScreenWeddings}
            exact
          />
          <Route
            path='/transportations-groups/:groupId'
            component={GeneralHomeScreenGroups}
            exact
          />
          <Route
            path='/transportations-tours/:tourId'
            component={GeneralHomeScreenTours}
            exact
          />
          <Route
            path=
            '/transportations-trips/:tripId'
            component={GeneralHomeScreenTrips}
            exact
          />
          <Route
            path=
            '/transportations-hourly/:hourlyId'
            component={GeneralHomeScreenHourly}
            exact
          />
          <Route path='/services-rates' component={GeneralHomeScreen} exact />
          <Route path='/vehicles' component={VehiclesScreen} exact />
          <Route path='/testimonials' component={TestimonialsScreen} exact />
          <Route path='/location' component={LocationScreen} exact />
          <Route path='/contactus' component={ContactUsScreen} exact />
          <Route path='/instructions' component={InstructionsScreen} exact />          
          <Route path='/activities' component={GeneralHomeScreenActivities} exact />          
          <Route
            path='/activity/:activityId'
            component={GeneralHomeScreenSubActivities}
            exact
          />          
        </Container>
      </main>
      <FooterWix />      
    </Router>
  )
}

export default App
