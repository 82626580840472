import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "react-bootstrap"
import GeneralActivities from "../components/GeneralActivities"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { listActivities } from "../actions/productActions"
import "./css/screensStyling.css"

const GeneralHomeScreenActivities = () => {
  const dispatch = useDispatch()

  const activityList = useSelector((state) => state.activityList)

  const { loading, error, productsActivity } = activityList

  useEffect(() => {
    dispatch(listActivities())
  }, [dispatch])

  return (
    <>
      <h1 class='screensH1'>Activities</h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          {productsActivity.map((product, index) => (
            <Col key={product._id} sm={12} md={6} lg={4} xl={4}>
              <GeneralActivities product={product} projectId={1} />
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default GeneralHomeScreenActivities
