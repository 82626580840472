import React, { useState, useEffect } from "react"
import moment from "moment"
import { PayPalButton } from "react-paypal-button-v2"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Card, Modal, Button } from "react-bootstrap"
import Loader from "../components/Loader"
import Message from "../components/Message"
import { weddingDetails } from "../actions/productActions"
import ArrivalTrip from "../components/ArrivalTrip"
import DepartureTrip from "../components/DepartureTrip"
var CurrencyFormat = require("react-currency-format")

// import Rating from "../components/Rating"
//Before Redux
//import axios from 'axios'
function RoundOrOneWayTrip(props) {
  const isRoundTrip = props.isRoundTrip
  const selectedArrivalAirline = props.selectedArrivalAirline
  const selectedDepartureAirline = props.selectedDepartureAirline
  const setArrivalData = props.setArrivalData
  const arrivalData = props.arrivalData
  const setDepartureData = props.setDepartureData
  const departureData = props.departureData
  const isHotelItineraries = props.isHotelItineraries
  const hotelId = props.hotelId
  const setIsDifferenceOK = props.setIsDifferenceOK
  const isDifferenceOK = props.isDifferenceOK

  if (isRoundTrip === "Round") {
    return (
      <>
        <ArrivalTrip
          selectedArrivalAirline={selectedArrivalAirline}
          arrivalData={arrivalData}
          setArrivalData={setArrivalData}
        />
        <DepartureTrip
          selectedDepartureAirline={selectedDepartureAirline}
          departureData={departureData}
          setDepartureData={setDepartureData}
          isHotelItineraries={isHotelItineraries}
          hotelId={hotelId}
          setIsDifferenceOK={setIsDifferenceOK}
          isDifferenceOK={isDifferenceOK}
        />
      </>
    )
  }
  if (isRoundTrip === "Arrival") {
    return (
      <ArrivalTrip
        selectedArrivalAirline={selectedArrivalAirline}
        arrivalData={arrivalData}
        setArrivalData={setArrivalData}
      />
    )
  }
  if (isRoundTrip === "Departure") {
    return (
      <DepartureTrip
        selectedDepartureAirline={selectedDepartureAirline}
        departureData={departureData}
        setDepartureData={setDepartureData}
        isHotelItineraries={isHotelItineraries}
        hotelId={hotelId}
        setIsDifferenceOK={setIsDifferenceOK}
        isDifferenceOK={isDifferenceOK}
      />
    )
  }
}

const ProductScreenWeddings = ({ location, match }) => {
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [notes, setNotes] = useState("")
  const [arrivalAirline, selectedArrivalAirline] = useState(null)
  const [departureAirline, selectedDepartureAirline] = useState(null)
  const [arrivalData, setArrivalData] = useState({
    flight: "",
    date: "0000-00-00",
    time: "",
  })
  const [departureData, setDepartureData] = useState({
    flight: "",
    date: "0000-00-00",
    time: "",
    shuttleHour: "",
  })
  const [numOfPersons, setNumOfPersons] = useState(0)
  const [isDifferenceOK, setIsDifferenceOK] = useState(true)

  const {
    tripPrice,
    transportationNameSelected,
    kindOfTrip,
    hotelName,
    hotelId,
    serviceId,
    transportationIdSelected,
    isHotelItineraries,
    weddingId,
  } = location.state

  const [show, setShow] = useState(false)
  const [showArrivalDate, setShowArrivalDate] = useState(false)
  const [showArrivalTime, setShowArrivalTime] = useState(false)
  const [showArrivalFlightNumber, setShowArrivalFlightNumber] = useState(false)
  const [showDepartureDate, setShowDepartureDate] = useState(false)
  const [showDepartureTime, setShowDepartureTime] = useState(false)
  const [showDepartureFlightNumber, setShowDepartureFlightNumber] =
    useState(false)
  const [showArrivalAirline, setShowArrivalAirline] = useState(false)
  const [showDepartureAirline, setShowDepartureAirline] = useState(false)
  const [showRightArrivalDepartureDates, setShowRightArrivalDepartureDates] =
    useState(false)

  const [showPaypalComplete, setShowPaypalComplete] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleCloseArrivalDate = () => setShowArrivalDate(false)
  const handleShowArrivalDate = () => setShowArrivalDate(true)

  const handleCloseArrivalTime = () => setShowArrivalTime(false)
  const handleShowArrivalTime = () => setShowArrivalTime(true)

  const handleCloseArrivalFlightNumber = () => setShowArrivalFlightNumber(false)
  const handleShowArrivalFlightNumber = () => setShowArrivalFlightNumber(true)

  const handleCloseDepartureDate = () => setShowDepartureDate(false)
  const handleShowDepartureDate = () => setShowDepartureDate(true)

  const handleCloseDepartureTime = () => setShowDepartureTime(false)
  const handleShowDepartureTime = () => setShowDepartureTime(true)

  const handleCloseDepartureFlightNumber = () =>
    setShowDepartureFlightNumber(false)
  const handleShowDepartureFlightNumber = () =>
    setShowDepartureFlightNumber(true)

  const handleCloseArrivalAirline = () => setShowArrivalAirline(false)
  const handleShowArrivalAirline = () => setShowArrivalAirline(true)

  const handleCloseDepartureAirline = () => setShowDepartureAirline(false)
  const handleShowDepartureAirline = () => setShowDepartureAirline(true)

  const handleCloseRightArrivalDepartureDates = () =>
    setShowRightArrivalDepartureDates(false)
  const handleShowRightArrivalDepartureDates = () =>
    setShowRightArrivalDepartureDates(true)

  const handleClosePaypalComplete = () => setShowPaypalComplete(false)
  const handleShowPaypalComplete = () => setShowPaypalComplete(true)

  const dispatch = useDispatch()

  const weddingDetailsInfo = useSelector((state) => state.weddingDetailsInfo)
  const { loading, error, wedding } = weddingDetailsInfo
  const server = "https://transportation-backend-test.link"

  let weddingName = wedding.name

  const getLimitNumOfPassengers = (transportationNameSelected, productName) => {
    var numberOfPassengers = []
    if (productName === "Shuttle") {
      numberOfPassengers = transportationNameSelected.split(" ")
      return numberOfPassengers[1]
    } else {
      numberOfPassengers = transportationNameSelected.split("-")
      if (numberOfPassengers.length === 1) {
        numberOfPassengers = transportationNameSelected.split(" ")
        return numberOfPassengers[1]
      } else {
        return numberOfPassengers[1]
      }
    }
  }

  const limitNumOfPassengers = getLimitNumOfPassengers(
    transportationNameSelected,
    weddingName
  )
  function toPayHandler(event) {
    event.preventDefault()

    if (kindOfTrip === "Arrival") {
      if (arrivalData.date === "0000-00-00") {
        handleShowArrivalDate()
        return
      }

      if (arrivalData.time === "") {
        handleShowArrivalTime()
        return
      }

      if (arrivalData.flight === "") {
        handleShowArrivalFlightNumber()
        return
      }

      if (arrivalAirline === undefined || arrivalAirline === "") {
        handleShowArrivalAirline()
        return
      }
    }

    if (kindOfTrip === "Departure") {
      if (departureData.date === "0000-00-00") {
        handleShowDepartureDate()
        return
      }

      if (departureData.time === "") {
        handleShowDepartureTime()
        return
      }

      if (departureData.flight === "") {
        handleShowDepartureFlightNumber()
        return
      }

      if (departureAirline === undefined || departureAirline === "") {
        handleShowDepartureAirline()
        return
      }
    }

    if (kindOfTrip === "Round") {
      if (arrivalData.date === "0000-00-00") {
        handleShowArrivalDate()
        return
      }

      if (arrivalData.time === "") {
        handleShowArrivalTime()
        return
      }

      if (arrivalData.flight === "") {
        handleShowArrivalFlightNumber()
        return
      }

      if (arrivalAirline === undefined || arrivalAirline === "") {
        handleShowArrivalAirline()
        return
      }

      if (departureData.date === "0000-00-00") {
        handleShowDepartureDate()
        return
      }

      if (departureData.time === "") {
        handleShowDepartureTime()
        return
      }

      if (departureData.flight === "") {
        handleShowDepartureFlightNumber()
        return
      }

      if (departureAirline === undefined || departureAirline === "") {
        handleShowDepartureAirline()
        return
      }

      if (arrivalData.date > departureData.date) {
        handleShowRightArrivalDepartureDates()
        return
      }
    }

    handleShow()
  }
  useEffect(() => {
    dispatch(weddingDetails(match.params.id, match.params.weddingId))
  }, [dispatch, match])

  return (
    <>
      <Link
        className='btn btn-light my-3'
        to={`/transportations-weddings/${weddingId}`}
      >
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container fluid>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={4} md={4} lg={4}>
              <Card className='text-center'>
                <Card.Img
                  variant='top'
                  src={wedding.image}
                  alt={transportationNameSelected}
                />
                <Card.Body>
                  <Card.Title>{transportationNameSelected} </Card.Title>
                  <Card.Title>{hotelName} Hotel</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='text-center'>
            <Col sm={12}>
              <RoundOrOneWayTrip
                isRoundTrip={kindOfTrip}
                selectedArrivalAirline={selectedArrivalAirline}
                selectedDepartureAirline={selectedDepartureAirline}
                setArrivalData={setArrivalData}
                arrivalData={arrivalData}
                setDepartureData={setDepartureData}
                departureData={departureData}
                isHotelItineraries={isHotelItineraries}
                hotelId={hotelId}
                isDifferenceOK={isDifferenceOK}
                setIsDifferenceOK={setIsDifferenceOK}
              />

              <Form onSubmit={toPayHandler}>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={2}>
                    <Form.Label>Number Of Passengers:</Form.Label>
                    <Form.Control
                      type='number'
                      value={numOfPersons}
                      onChange={(e) => setNumOfPersons(e.target.value)}
                      min='1'
                      max={limitNumOfPassengers}
                      required
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      type='text'
                      size='30'
                      placeholder='Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      type='text'
                      size='30'
                      placeholder='Last Name'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      type='email'
                      placeholder='Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Col>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      name='notes'
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder='Notes'
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={3} className='m-1'>
                    <Form.Label>Total:</Form.Label>
                    <CurrencyFormat
                      value={tripPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  {isDifferenceOK && (
                    <Button type='submit' variant='outline-dark' size='md'>
                      Pay
                    </Button>
                  )}
                </Form.Row>
              </Form>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <PayPalButton
              amount={tripPrice}
              // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
              onSuccess={(details, data) => {
                let today = moment().format("YYYY-MM-DD")
                let arrivalNumOfPassengers = 0
                let departureNumOfPassengers = 0
                let IdArrivalService = null
                let IdDepartureService = null
                let IdArrivalTransportation = null
                let IdDepartureTransportation = null
                let IdTrip = null
                var time = moment().format("hh:mm:ss")
                let arrivalHotelName = ""
                let departureHotelName = ""
                let arrivalTransportation = ""
                let departureTransportation = ""

                if (kindOfTrip === "Arrival") {
                  IdTrip = 1
                  IdArrivalService = serviceId
                  arrivalNumOfPassengers = numOfPersons
                  IdArrivalTransportation = transportationIdSelected
                  arrivalData.date = moment(arrivalData.date).format(
                    "YYYY-MM-DD"
                  )
                  departureData.date = ""
                  departureData.shuttleHour = ""
                  arrivalHotelName = hotelName
                  arrivalTransportation = transportationNameSelected
                }

                if (kindOfTrip === "Departure") {
                  IdTrip = 2
                  IdDepartureService = serviceId
                  departureNumOfPassengers = numOfPersons
                  IdDepartureTransportation = transportationIdSelected
                  departureData.date = moment(departureData.date).format(
                    "YYYY-MM-DD"
                  )
                  if (isHotelItineraries !== "") {
                    departureData.shuttleHour = moment(
                      departureData.shuttleHour,
                      "hh:mm a"
                    ).format("hh:mm a")
                  } else {
                    departureData.shuttleHour = ""
                  }
                  arrivalData.date = ""
                  departureHotelName = hotelName
                  departureTransportation = transportationNameSelected
                }

                if (kindOfTrip === "Round") {
                  IdTrip = 3
                  IdArrivalService = serviceId
                  arrivalNumOfPassengers = numOfPersons
                  IdArrivalTransportation = transportationIdSelected
                  arrivalData.date = moment(arrivalData.date).format(
                    "YYYY-MM-DD"
                  )
                  arrivalHotelName = hotelName
                  arrivalTransportation = transportationNameSelected

                  IdDepartureService = serviceId
                  departureNumOfPassengers = numOfPersons
                  IdDepartureTransportation = transportationIdSelected
                  departureData.date = moment(departureData.date).format(
                    "YYYY-MM-DD"
                  )
                  if (isHotelItineraries !== "") {
                    departureData.shuttleHour = moment(
                      departureData.shuttleHour,
                      "hh:mm a"
                    ).format("hh:mm a")
                  } else {
                    departureData.shuttleHour = ""
                  }
                  departureHotelName = hotelName
                  departureTransportation = transportationNameSelected
                }

                handleShowPaypalComplete()

                return fetch(`${server}/paypal-transaction-complete-wedding`, {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    orderID: data.orderID,
                    FechaPago: today,
                    FechaSalida: departureData.date,
                    FechaLlegada: arrivalData.date,
                    HoraLlegada: arrivalData.time,
                    HoraSalida: departureData.time,
                    FechaControl: today,
                    Usuario: "web",
                    Email: email,
                    Name: name,
                    LastName: lastName,
                    IdArrivalAirline: arrivalAirline,
                    IdDepartureAirline: departureAirline,
                    PersonasLlegada: arrivalNumOfPassengers,
                    PersonasSalida: departureNumOfPassengers,
                    NumeroLlegada: arrivalData.flight,
                    NumeroSalida: departureData.flight,
                    IdHotel: hotelId,
                    IdTrip: IdTrip,
                    IdArrivalService: IdArrivalService,
                    IdDepartureService: IdDepartureService,
                    IdArrivalTransportation: IdArrivalTransportation,
                    IdDepartureTransportation: IdDepartureTransportation,
                    ShuttleDepartureTime: departureData.shuttleHour,
                    ShuttleDepartureTimeFormatted: departureData.shuttleHour,
                    ImporteTotalDlls: tripPrice,
                    Notas: notes.slice(0,100),
                    Hora: time,
                    PaypalAmount: details.purchase_units[0].amount.value,
                    PaypalCurrency_code:
                      details.purchase_units[0].amount.currency_code,
                    PaypalEmail_address: details.payer.email_address,
                    PaypalName: details.payer.name.given_name,
                    PaypalLastName: details.payer.name.surname,
                    PaypalPayerId: details.payer.payer_id,
                    PaypalStatus: details.status,
                    PaypalId: details.id,
                    transportationNameSelected: transportationNameSelected,
                    kindOfTrip: kindOfTrip,
                    hotelName: hotelName,
                    arrivalHotelName: arrivalHotelName,
                    departureHotelName: departureHotelName,
                    arrivalTransportation: arrivalTransportation,
                    departureTransportation: departureTransportation,
                    isHotelItineraries: isHotelItineraries,
                    project: weddingId,
                    campaign: 1,
                  }),
                })
              }}
              options={{
                clientId:
                  "AYZm0tquYgY-AShbUdA0qsBvLsMINEHXUAkxmlRk4ij1zXWDZ5xdRfebGWUmjePKGjA7FvzdFb2KbDyA",
              }}
            />
          </Modal>

          <Modal show={showArrivalDate} onHide={handleCloseArrivalDate}>
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Arrival Date</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal show={showArrivalTime} onHide={handleCloseArrivalTime}>
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Arrival Time</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            show={showArrivalFlightNumber}
            onHide={handleCloseArrivalFlightNumber}
          >
            <Modal.Header closeButton>
              <Modal.Title>Please Enter The Arrival Flight Number</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal show={showArrivalAirline} onHide={handleCloseArrivalAirline}>
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Arrival Airline</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal show={showDepartureDate} onHide={handleCloseDepartureDate}>
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Departure Date</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal show={showDepartureTime} onHide={handleCloseDepartureTime}>
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Departure Time</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            show={showDepartureFlightNumber}
            onHide={handleCloseDepartureFlightNumber}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Please Enter The Departure Flight Number
              </Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            show={showDepartureAirline}
            onHide={handleCloseDepartureAirline}
          >
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Departure Airline</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            show={showRightArrivalDepartureDates}
            onHide={handleCloseRightArrivalDepartureDates}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                The Departure Date Must Be After The Arrival Date
              </Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            backdrop='static'
            keyboard={false}
            show={showPaypalComplete}
            onHide={handleClosePaypalComplete}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Transaction Complete, Please Check Your Email...
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button variant='primary' onClick={handleClosePaypalComplete}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  )
}

export default ProductScreenWeddings
