import React from "react"
import { Radio } from "antd"
import { Link } from "react-router-dom"
import { Card, Button } from "react-bootstrap"

const GeneralActivities = ({ product, projectId }) => {
  return (
    <div>
      <Card className='text-center'>
        <Card.Img
          src={product.image}
          variant='top'
          width='418px'
          height='279px'
        />
        <Card.Body>
          <Card.Title as='h4'>
            <strong>{product.name}</strong>
          </Card.Title>
          <Card.Text as='h5'>{product.description}</Card.Text>
          <Radio.Group buttonStyle='solid'>
            <Card.Text as='h6'>
            </Card.Text>
            <Link
              to={{
                pathname: `/activity/${product._id}`
              }}
            >
              <Button variant='outline-dark' size='md'>
                Select
              </Button>
            </Link>
          </Radio.Group>

        </Card.Body>
      </Card>
    </div>
  )
}

export default GeneralActivities
