import { HOTEL_RATES_LIST_REQUEST,
    HOTEL_RATES_LIST_SUCCESS,
    HOTEL_RATES_LIST_FAIL } from '../constants/rateConstants'

    // export const hotelRatesListReducer=(state={hotelRates:[]},action)=>{
    //     switch(action.type){
    //         case HOTEL_RATES_LIST_REQUEST:
    //             return {loadingHotelRates:true,hotelRates:[]}
    //         case HOTEL_RATES_LIST_SUCCESS:
    //             return {loadingHotelRates:false,hotelRates: action.payload}
    //         case HOTEL_RATES_LIST_FAIL:
    //             return {loadingHotelRates:false,hotelRatesError:action.payload}
    //         default:
    //             return state
    //     }
    // }

    export const hotelRatesListReducer=(
        state={hotelRates:[]},
        action
        )=>{
        switch(action.type){
            case HOTEL_RATES_LIST_REQUEST:
                return {loading:true,...state}
            case HOTEL_RATES_LIST_SUCCESS:
                return {loading:false,hotelRates: action.payload}
            case HOTEL_RATES_LIST_FAIL:
                return {loading:false,error:action.payload}
            default:
                return state
        }
    }
    