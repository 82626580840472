import React from 'react'
import { Carousel } from "react-bootstrap"
import "react-multi-carousel/lib/styles.css";

function HeaderCarousel() {
    return (
        
        <Carousel>
            <Carousel.Item>
            
                <img
                className="d-block w-100"
                src="./images/01.jpg"
                alt="First slide"
    />
                <Carousel.Caption>
                    
                </Carousel.Caption>

            </Carousel.Item>

            <Carousel.Item>
                <img
                className="d-block w-100"
                src="./images/03.jpg"
                alt="Second slide"
                />

                <Carousel.Caption>
                    
                </Carousel.Caption>

            </Carousel.Item>

            <Carousel.Item>
                <img
                className="d-block w-100"
                src="./images/unidades_loscabos.jpg"
                alt="Third slide"
                />

                <Carousel.Caption>
                    
                </Carousel.Caption>

            </Carousel.Item>       

            <Carousel.Item>
                <img
                className="d-block w-100"
                src="./images/transportacion_los_cabos_mosaico.jpg"
                alt="Fourth slide"
                />

                <Carousel.Caption>
                    
                </Carousel.Caption>

            </Carousel.Item>   

            <Carousel.Item>
                <img
                className="d-block w-100"
                src="./images/limousines_loscabos_banner.jpg"
                alt="Fifth slide"
                />

                <Carousel.Caption>
                    
                </Carousel.Caption>

            </Carousel.Item>   

        </Carousel> 
    )
}

export default HeaderCarousel
