import React, { useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux'
import { Row, Col } from "react-bootstrap"
import GeneralServices from '../components/GeneralServices'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {listProducts} from '../actions/productActions'
import './css/screensStyling.css'

const GeneralHomeScreen = () => {
  const dispatch=useDispatch()

  const productList=useSelector(state=>state.productList)

  const {loading,error,products} =productList

  useEffect(() => {

    dispatch(listProducts())
    
     }, [dispatch])
  

  return (
    <>
      <h1 class="screensH1">Please Select Your Transportation</h1>
     
      {loading?<Loader/>:error?<Message variant='danger'>{error}</Message>: 
      <Row>
          {products.map((product,index) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={4}>
                  <GeneralServices product={product} projectId={1} />                  
              </Col>
          ))}    
      </Row>
      }     
    </>
  )
}

export default GeneralHomeScreen
