export const PRODUCT_LIST_REQUEST='PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS='PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL='PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST='PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS='PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL='PRODUCT_DETAILS_FAIL'

export const HOTEL_LIST_REQUEST='HOTEL_LIST_REQUEST'
export const HOTEL_LIST_SUCCESS='HOTEL_LIST_SUCCESS'
export const HOTEL_LIST_FAIL='HOTEL_LIST_FAIL'

export const PRODUCT_LIST_REQUEST_SOLAZ='PRODUCT_LIST_REQUEST_SOLAZ'
export const PRODUCT_LIST_SUCCESS_SOLAZ='PRODUCT_LIST_SUCCESS_SOLAZ'
export const PRODUCT_LIST_FAIL_SOLAZ='PRODUCT_LIST_FAIL_SOLAZ'

export const PRODUCT_DETAILS_REQUEST_SOLAZ='PRODUCT_DETAILS_REQUEST_SOLAZ'
export const PRODUCT_DETAILS_SUCCESS_SOLAZ='PRODUCT_DETAILS_SUCCESS_SOLAZ'
export const PRODUCT_DETAILS_FAIL_SOLAZ='PRODUCT_DETAILS_FAIL_SOLAZ'

export const PRODUCT_LIST_REQUEST_PROJECTS='PRODUCT_LIST_REQUEST_PROJECTS'
export const PRODUCT_LIST_SUCCESS_PROJECTS='PRODUCT_LIST_SUCCESS_PROJECTS'
export const PRODUCT_LIST_FAIL_PROJECTS='PRODUCT_LIST_FAIL_PROJECTS'

export const PROJECTS_INFO_REQUEST='PROJECTS_INFO_REQUEST'
export const PROJECTS_INFO_SUCCESS='PROJECTS_INFO_SUCCESS'
export const PROJECTS_INFO_FAIL='PROJECTS_INFO_FAIL'

export const PROJECT_DETAILS_REQUEST='PROJECT_DETAILS_REQUEST'
export const PROJECT_DETAILS_SUCCESS='PROJECT_DETAILS_SUCCESS'
export const PROJECT_DETAILS_FAIL='PROJECT_DETAILS_FAIL'

export const PRODUCT_LIST_REQUEST_WEDDINGS='PRODUCT_LIST_REQUEST_PROJECTS'
export const PRODUCT_LIST_SUCCESS_WEDDINGS='PRODUCT_LIST_SUCCESS_PROJECTS'
export const PRODUCT_LIST_FAIL_WEDDINGS='PRODUCT_LIST_FAIL_PROJECTS'

export const PRODUCT_LIST_REQUEST_GROUPS='PRODUCT_LIST_REQUEST_PROJECTS'
export const PRODUCT_LIST_SUCCESS_GROUPS='PRODUCT_LIST_SUCCESS_PROJECTS'
export const PRODUCT_LIST_FAIL_GROUPS='PRODUCT_LIST_FAIL_PROJECTS'

export const PRODUCT_LIST_REQUEST_TOURS='PRODUCT_LIST_REQUEST_PROJECTS'
export const PRODUCT_LIST_SUCCESS_TOURS='PRODUCT_LIST_SUCCESS_PROJECTS'
export const PRODUCT_LIST_FAIL_TOURS='PRODUCT_LIST_FAIL_PROJECTS'

export const PRODUCT_LIST_REQUEST_TRIPS='PRODUCT_LIST_REQUEST_PROJECTS'
export const PRODUCT_LIST_SUCCESS_TRIPS='PRODUCT_LIST_SUCCESS_PROJECTS'
export const PRODUCT_LIST_FAIL_TRIPS='PRODUCT_LIST_FAIL_PROJECTS'

export const PRODUCT_LIST_REQUEST_HOURLY='PRODUCT_LIST_REQUEST_PROJECTS'
export const PRODUCT_LIST_SUCCESS_HOURLY='PRODUCT_LIST_SUCCESS_PROJECTS'
export const PRODUCT_LIST_FAIL_HOURLY='PRODUCT_LIST_FAIL_PROJECTS'


export const WEDDINGS_INFO_REQUEST='PROJECTS_INFO_REQUEST'
export const WEDDINGS_INFO_SUCCESS='PROJECTS_INFO_SUCCESS'
export const WEDDINGS_INFO_FAIL='PROJECTS_INFO_FAIL'

export const WEDDING_DETAILS_REQUEST='PROJECT_DETAILS_REQUEST'
export const WEDDING_DETAILS_SUCCESS='PROJECT_DETAILS_SUCCESS'
export const WEDDING_DETAILS_FAIL='PROJECT_DETAILS_FAIL'

export const GROUPS_INFO_REQUEST='PROJECTS_INFO_REQUEST'
export const GROUPS_INFO_SUCCESS='PROJECTS_INFO_SUCCESS'
export const GROUPS_INFO_FAIL='PROJECTS_INFO_FAIL'

export const GROUP_DETAILS_REQUEST='PROJECT_DETAILS_REQUEST'
export const GROUP_DETAILS_SUCCESS='PROJECT_DETAILS_SUCCESS'
export const GROUP_DETAILS_FAIL='PROJECT_DETAILS_FAIL'

export const TOURS_INFO_REQUEST='PROJECTS_INFO_REQUEST'
export const TOURS_INFO_SUCCESS='PROJECTS_INFO_SUCCESS'
export const TOURS_INFO_FAIL='PROJECTS_INFO_FAIL'

export const TOUR_DETAILS_REQUEST='PROJECT_DETAILS_REQUEST'
export const TOUR_DETAILS_SUCCESS='PROJECT_DETAILS_SUCCESS'
export const TOUR_DETAILS_FAIL='PROJECT_DETAILS_FAIL'

export const TRIPS_INFO_REQUEST='PROJECTS_INFO_REQUEST'
export const TRIPS_INFO_SUCCESS='PROJECTS_INFO_SUCCESS'
export const TRIPS_INFO_FAIL='PROJECTS_INFO_FAIL'

export const TRIP_DETAILS_REQUEST='PROJECT_DETAILS_REQUEST'
export const TRIP_DETAILS_SUCCESS='PROJECT_DETAILS_SUCCESS'
export const TRIP_DETAILS_FAIL='PROJECT_DETAILS_FAIL'

export const HOURLY_INFO_REQUEST='PROJECTS_INFO_REQUEST'
export const HOURLY_INFO_SUCCESS='PROJECTS_INFO_SUCCESS'
export const HOURLY_INFO_FAIL='PROJECTS_INFO_FAIL'

export const HOURLY_DETAILS_REQUEST='PROJECT_DETAILS_REQUEST'
export const HOURLY_DETAILS_SUCCESS='PROJECT_DETAILS_SUCCESS'
export const HOURLY_DETAILS_FAIL='PROJECT_DETAILS_FAIL'

export const ACTIVITY_LIST_REQUEST='ACTIVITY_LIST_REQUEST'
export const ACTIVITY_LIST_SUCCESS='ACTIVITY_LIST_SUCCESS'
export const ACTIVITY_LIST_FAIL='ACTIVITY_LIST_FAIL'

export const SUBACTIVITIES_LIST_REQUEST='SUBACTIVITIES_LIST_REQUEST'
export const SUBACTIVITIES_LIST_SUCCESS='SUBACTIVITIES_LIST_SUCCESS'
export const SUBACTIVITIES_LIST_FAIL='SUBACTIVITIES_LIST_FAIL'