import React, { useState } from "react";
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
 import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Schedule = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
    selected={startDate}
    onChange={(date) => setStartDate(date)}
   excludeDateIntervals={[
      { start: "2024/08/08", end: "2024/08/08" },
      { start: "2024/01/18", end: "2024/01/24" },
    ]}
    placeholderText="Select a date other than the interval from 5 days ago to 5 days in the future"
  />

  );
};
  
  export default Schedule;