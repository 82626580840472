import React from 'react'
import { Card, Form,Col,Alert } from "react-bootstrap"

function ShuttleItineraryAlert() {
    return (
        <div>
      <Card.Text as='h6'><strong></strong></Card.Text>
    <Card className="text-center">
      <Card.Body>
         <Alert variant='danger'>
                The time difference between the flight and the shuttle departure must be at least 3 hours...
         </Alert>
      </Card.Body>
    </Card>        
    </div>
    )
}

export default ShuttleItineraryAlert
