import React from 'react'
import { Card, Form } from "react-bootstrap"

function GeneralTransportationsTour({ product, transportationNameSelected, transportationId, numOfPass, onChange })  {
  return (
        <div>
        <Card.Text as='h6'><strong>Select Number Of Passengers</strong></Card.Text>
    <Card className="text-center">
      <Card.Body>
          <Card.Title as='h4'>
        <Form.Control as="select" required
          value={transportationNameSelected}
          onChange={onChange}>              
          {product.chargebyperson === 1
                ? [...Array(product.numofpassengers + 1).keys()].map(
                    (x, index) => (
                      <option
                        key={index}
                        data-transpid={transportationId[x]}
                        value={numOfPass[x]}
                        data-numofpass={[x]}
                      >
                        {numOfPass[x]}
                      </option>
                    )
                  )
                : numOfPass.map((x, index) => (
                    <option
                      key={index}
                      data-transpid={transportationId[index]}
                      value={x}
                      data-numofpass={[x]}
                    >
                      {x}
                    </option>
            ))
            }
          </Form.Control> 
             </Card.Title>
      </Card.Body>
    </Card>
        </div>
    )
}

export default GeneralTransportationsTour
