import { SAVE_BUY_DATA } from '../constants/payConstants'

export const payReducer=(state={buydata:[]},action)=>{
    switch(action.type){
        case SAVE_BUY_DATA:
            return {
                ...state,
                buyData:action.payload,
            }
        default:
            return state
    }
}

