import React from 'react'
import { Card, Button } from "react-bootstrap"
import { Link } from  'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function SpecialOcassionsCarousel() {
    return (
        <div  class="text-center">
          <Carousel swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px">

            <div>

              <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/todos-santos-275x183.jpg" />
                <Card.Body>
                  <Card.Title>Todos Santos Tour</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-tours/11"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>

          <div>

             <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/La-Paz-Baja-California-Sur.jpg" />
                <Card.Body>
                  <Card.Title>La Paz Tour</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-tours/12"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>

          <div>

             <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/loreto.jpg" />
                <Card.Body>
                  <Card.Title>Loreto Tour</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-tours/23"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>

          <div>

             <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/floras-farm.jpeg" />
                <Card.Body>
                  <Card.Title>Flora Farms Tour</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-tours/13"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>

          <div>

             <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/sjd-corredor-csl.jpg" />
                <Card.Body>
                  <Card.Title>SJD-Corridor-CSL Tour</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-tours/14"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>          

          <div>

             <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/migri%C3%B1o.jpg" />
                <Card.Body>
                  <Card.Title>Migriño Tour</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-tours/15"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>                

          <div>

             <Card style={{ width: '18rem' }} >
                <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/viaje-la-paz.jpg" />
                <Card.Body>
                  <Card.Title>La Paz Trip</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                  <Link to="/transportations-trips/16"> <Button variant="primary">Reserve</Button></Link>
                </Card.Body>
             </Card>

          </div>    

          <div>
            
            <Card style={{ width: '18rem' }} >
                  <Card.Img variant="top" src="https://transportationshop-images.s3.us-west-1.amazonaws.com/hourly.jpg" />
                  <Card.Body>
                    <Card.Title>Hourly Service</Card.Title>
                    <Card.Text>
                      
                    </Card.Text>
                    <Link to="/transportations-hourly/17"> <Button variant="primary">Reserve</Button></Link>
                  </Card.Body>
            </Card>
          

          </div>      
          
          </Carousel>        

    </div>
    )
}

export default SpecialOcassionsCarousel
