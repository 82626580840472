import axios from "axios"
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PROJECT_DETAILS_REQUEST,
  PROJECT_DETAILS_SUCCESS,
  PROJECT_DETAILS_FAIL,
  WEDDING_DETAILS_REQUEST,
  WEDDING_DETAILS_SUCCESS,
  WEDDING_DETAILS_FAIL,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  TOUR_DETAILS_REQUEST,
  TOUR_DETAILS_SUCCESS,
  TOUR_DETAILS_FAIL,
  TRIP_DETAILS_REQUEST,
  TRIP_DETAILS_SUCCESS,
  TRIP_DETAILS_FAIL,
  HOURLY_DETAILS_REQUEST,
  HOURLY_DETAILS_SUCCESS,
  HOURLY_DETAILS_FAIL,
  HOTEL_LIST_REQUEST,
  HOTEL_LIST_SUCCESS,
  HOTEL_LIST_FAIL,
  PRODUCT_LIST_REQUEST_SOLAZ,
  PRODUCT_LIST_SUCCESS_SOLAZ,
  PRODUCT_LIST_FAIL_SOLAZ,
  PRODUCT_LIST_REQUEST_PROJECTS,
  PRODUCT_LIST_SUCCESS_PROJECTS,
  PRODUCT_LIST_FAIL_PROJECTS,
  PRODUCT_LIST_REQUEST_WEDDINGS,
  PRODUCT_LIST_SUCCESS_WEDDINGS,
  PRODUCT_LIST_FAIL_WEDDINGS,
  PRODUCT_LIST_REQUEST_GROUPS,
  PRODUCT_LIST_SUCCESS_GROUPS,
  PRODUCT_LIST_FAIL_GROUPS,
  PRODUCT_LIST_REQUEST_TOURS,
  PRODUCT_LIST_SUCCESS_TOURS,
  PRODUCT_LIST_FAIL_TOURS,
  PRODUCT_LIST_REQUEST_TRIPS,
  PRODUCT_LIST_SUCCESS_TRIPS,
  PRODUCT_LIST_FAIL_TRIPS,
  PRODUCT_LIST_REQUEST_HOURLY,
  PRODUCT_LIST_SUCCESS_HOURLY,
  PRODUCT_LIST_FAIL_HOURLY,
  PRODUCT_DETAILS_REQUEST_SOLAZ,
  PRODUCT_DETAILS_SUCCESS_SOLAZ,
  PRODUCT_DETAILS_FAIL_SOLAZ,
  PROJECTS_INFO_REQUEST,
  PROJECTS_INFO_SUCCESS,
  PROJECTS_INFO_FAIL,
  WEDDINGS_INFO_REQUEST,
  WEDDINGS_INFO_SUCCESS,
  WEDDINGS_INFO_FAIL,
  GROUPS_INFO_REQUEST,
  GROUPS_INFO_SUCCESS,
  GROUPS_INFO_FAIL,
  TOURS_INFO_REQUEST,
  TOURS_INFO_SUCCESS,
  TOURS_INFO_FAIL,
  HOURLY_INFO_REQUEST,
  HOURLY_INFO_SUCCESS,
  HOURLY_INFO_FAIL,
  TRIPS_INFO_REQUEST,
  TRIPS_INFO_SUCCESS,
  TRIPS_INFO_FAIL,
  ACTIVITY_LIST_REQUEST,
  ACTIVITY_LIST_SUCCESS,
  ACTIVITY_LIST_FAIL,
  SUBACTIVITIES_LIST_REQUEST,
  SUBACTIVITIES_LIST_SUCCESS,
  SUBACTIVITIES_LIST_FAIL,
} from "../constants/productConstants"

const server = "https://transportation-backend-test.link"

export const listProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST })
    const { data } = await axios.get(`${server}/services`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listHotels = () => async (dispatch) => {
  try {
    dispatch({ type: HOTEL_LIST_REQUEST })

    const { data } = await axios.get(`${server}/hotels`)

    dispatch({
      type: HOTEL_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HOTEL_LIST_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const { data } = await axios.get(`${server}/services/${id}`)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listGroupDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_DETAILS_REQUEST })

    const { data } = await axios.get(`${server}/services/group/${id}`)

    dispatch({
      type: GROUP_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GROUP_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductsSolaz = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_SOLAZ })
    const { data } = await axios.get(`${server}/services-solaz`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_SOLAZ,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_SOLAZ,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductDetailsSolaz = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST_SOLAZ })

    const { data } = await axios.get(`${server}/services-solaz/${id}`)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS_SOLAZ,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL_SOLAZ,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/////Projects

export const listProductsProjects = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_PROJECTS })
    const { data } = await axios.get(`${server}/services-projects/${projectId}`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_PROJECTS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_PROJECTS,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProjectsInfo = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: PROJECTS_INFO_REQUEST })
    const { data } = await axios.get(`${server}/projects-info/${projectId}`)
    dispatch({
      type: PROJECTS_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECTS_INFO_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const projectDetails = (id, projectId) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_DETAILS_REQUEST })

    const { data } = await axios.get(
      `${server}/services-projects/${id}/${projectId}`
    )

    dispatch({
      type: PROJECT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/////Weddings

export const listProductsWeddings = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_WEDDINGS })
    const { data } = await axios.get(`${server}/services-projects/${projectId}`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_WEDDINGS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_WEDDINGS,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getWeddingsInfo = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: WEDDINGS_INFO_REQUEST })
    const { data } = await axios.get(`${server}/projects-info/${projectId}`)

    dispatch({
      type: WEDDINGS_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: WEDDINGS_INFO_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const weddingDetails = (id, weddingId) => async (dispatch) => {
  try {
    dispatch({ type: WEDDING_DETAILS_REQUEST })

    const { data } = await axios.get(
      `${server}/services-weddings/${id}/${weddingId}`
    )

    dispatch({
      type: WEDDING_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: WEDDING_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/////Groups

export const listProductsGroups = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_GROUPS })
    const { data } = await axios.get(`${server}/services-projects/${groupId}`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_GROUPS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_GROUPS,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getGroupsInfo = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: GROUPS_INFO_REQUEST })
    const { data } = await axios.get(`${server}/projects-info/${groupId}`)

    dispatch({
      type: GROUPS_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GROUPS_INFO_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const groupDetails = (id, groupId) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_DETAILS_REQUEST })

    const { data } = await axios.get(
      `${server}/services-groups/${id}/${groupId}`
    )

    dispatch({
      type: GROUP_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GROUP_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/////Tours
export const listProductsTours = (tourId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_TOURS })
    const { data } = await axios.get(`${server}/services-projects/${tourId}`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_TOURS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_TOURS,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getToursInfo = (tourId) => async (dispatch) => {
  try {
    dispatch({ type: TOURS_INFO_REQUEST })
    const { data } = await axios.get(`${server}/projects-info/${tourId}`)

    dispatch({
      type: TOURS_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURS_INFO_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const tourDetails = (id, tourId) => async (dispatch) => {
  try {
    dispatch({ type: TOUR_DETAILS_REQUEST })

    const { data } = await axios.get(`${server}/services-tours/${id}/${tourId}`)

    dispatch({
      type: TOUR_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOUR_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/////Trips

export const listProductsTrips = (tripId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_TRIPS })
    const { data } = await axios.get(`${server}/services-projects/${tripId}`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_TRIPS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_TRIPS,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTripsInfo = (tripId) => async (dispatch) => {
  try {
    dispatch({ type: TRIPS_INFO_REQUEST })
    const { data } = await axios.get(`${server}/projects-info`)

    dispatch({
      type: TRIPS_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIPS_INFO_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const tripDetails = (id, tripId) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_DETAILS_REQUEST })

    const { data } = await axios.get(`${server}/services-trips/${id}/${tripId}`)

    dispatch({
      type: TRIP_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIP_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/////Hourly
export const listProductsHourly = (hourlyId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST_HOURLY })
    const { data } = await axios.get(`${server}/services-projects/${hourlyId}`)
    dispatch({
      type: PRODUCT_LIST_SUCCESS_HOURLY,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL_HOURLY,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getHourlyInfo = (hourlyId) => async (dispatch) => {
  try {
    dispatch({ type: HOURLY_INFO_REQUEST })
    const { data } = await axios.get(`${server}/projects-info/${hourlyId}`)

    dispatch({
      type: HOURLY_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HOURLY_INFO_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hourlyDetails = (id, hourlyId) => async (dispatch) => {
  try {
    dispatch({ type: HOURLY_DETAILS_REQUEST })

    const { data } = await axios.get(
      `${server}/services-hourly/${id}/${hourlyId}`
    )

    dispatch({
      type: HOURLY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HOURLY_DETAILS_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listActivities = () => async (dispatch) => {
  try {
    dispatch({ type: ACTIVITY_LIST_REQUEST })
    const { data } = await axios.get(`${server}/activities`)
    dispatch({
      type: ACTIVITY_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ACTIVITY_LIST_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listSubActivities = (activityId) => async (dispatch) => {
  try {
    dispatch({ type: SUBACTIVITIES_LIST_REQUEST })
    const { data } = await axios.get(`${server}/sub-activities/${activityId}`)
    dispatch({
      type: SUBACTIVITIES_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SUBACTIVITIES_LIST_FAIL,
      payload:
        error.response && error.response.message
          ? error.response.data.message
          : error.message,
    })
  }
}
