import React, { useState } from "react"
import { Form, Col, Row, Button } from "react-bootstrap"
import axios from "axios"

const ContactUsScreen = () => {
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const sendingHandler = async (event) => {
    event.preventDefault()

    const server = "https://transportation-backend-test.link"

    const contactForm = {
      name: name,
      lastName: lastName,
      comments: comments,
      email,
    }

    try {
      const resp = await axios.post(`${server}/contact-us-form`, contactForm)
      alert("Email sent, we will in touch with you very soon.")
    } catch (err) {
      // Handle Error Here
      console.error(err)
    }
  }

  return (
    <>
      <Row className='justify-content-md-center'>
        <Col sm={12} md={12} lg={8} xl={8}>
          <p style={{ fontSize: 30, textAlign: "justify", paddingTop: "10px" }}>
            If you have questions or comments you can contact us by using our
            contact form below:
          </p>

          <Form onSubmit={sendingHandler}>
            <Form.Row className='justify-content-center m-2'>
              <Col lg={4} className='m-1'>
                <Form.Control
                  type='text'
                  size='30'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Col>
            </Form.Row>
            <Form.Row className='justify-content-center m-2'>
              <Col lg={4} className='m-1'>
                <Form.Control
                  type='text'
                  size='30'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Col>
            </Form.Row>
            <Form.Row className='justify-content-center m-2'>
              <Col lg={4} className='m-1'>
                <Form.Control
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Col>
            </Form.Row>
            <Form.Row className='justify-content-center m-2'>
              <Col lg={4} className='m-1'>
                <Form.Control
                  as='textarea'
                  rows='4'
                  name='comments'
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder='Comments'
                />
              </Col>
            </Form.Row>
            <Form.Row className='justify-content-center m-2'>
              <Button type='submit' variant='primary' size='md'>
                Send
              </Button>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ContactUsScreen
