import React from "react"
import {  Image,Jumbotron, Row, Col } from "react-bootstrap"


const InstructionsScreen = () => {
  return (
    <>
    <Row className='justify-content-md-center'>
        <Col sm={12} md={12} lg={8} xl={8}>
        <Jumbotron>
          <p style={{  fontSize: 30,textAlign: "center"}}>What do I do once I arrive to Los Cabos International Airport to get the vehicle I reserved?</p>
          <p style={{  fontSize: 15,textAlign: "justify", paddingTop: "10px" }}>follow these instructions:</p>
          <p style={{  fontSize: 15,textAlign: "justify", paddingTop: "10px" }}>Once you leave Los Cabos Airport Terminal, you will see a lot of our personal wearing brown color shirts holding <strong> Cabo Travel Solutions</strong> Signs, they will be waiting for you, you can also approach to them, present your id with your name (we will already have all your transportation information registered with your name, your destination, type of vehicle, etc.), you can also present your paypal voucher if you prefer.</p>
          <p style={{  fontSize: 15,textAlign: "justify", paddingTop: "10px" }}><strong>Here is an example of how our representatives are dressed like at the outside of los cabos international airport terminal</strong></p>
        </Jumbotron>
        <Image src="./images/cabo_travel_solutions-instructions-01.jpg" fluid />
        <Image style={{  paddingTop: "10px" }} src="./images/cabo_travel_solutions-instructions-02.jpg" fluid />
      </Col>
    </Row>
  
    </>
  )
}

export default InstructionsScreen
