import React from 'react';
import TestiMonialsDetails from '../TestiMonialsDetails/TestiMonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//import userPic from '../../image/user-one.png';
import './TestiMonials.css'

const TestiMonials = () => {
  
    const testiMonials = [
        {
            name: 'Olivia',
            description: 'I arrived in Cabo the day of my birthday, I was wearing a crazy hat because of that, suddenly the driver started to sing the Mexican version of HBD in Spanish to me! Priceless.',
            address: 'USA',
            // img: 'https://i.ibb.co/hgGJc8d/Gareth-Bale.jpg'
        },
        {
            name: 'Jason',
            description: 'Prices are low, new Subs and the service beyond belief!!!! I recommend it!',
            address: 'USA',            
        },
        {
            name: 'David S.',
            description: 'So happy to travel with cabo travel solutions, the driver was so funny LOL, he recommended us the best places to visit in Cabo.',
            address: 'USA',
        },
        {
            name: 'Alfie',
            description: 'This company has a 100 in punctuality & Great Service! Cheers!',
            address: 'USA',
        },
        {
            name: 'Doreen',
            description: 'I forgot my cellphone in one of their Suvs, I call to the management and they sent me my cellphone with one driver, I was so happy !!!!!!!!!!!!! Thanks Cabo Travel Solutions for your honestly.- Doreen.',
            address: 'USA',
        },
        {
            name: 'Thomas',
            description: 'We totally recommend cabo travel solutions!',
            address: 'USA',
        },
        {
            name: 'J. Collins',
            description: 'Amazing Services!',
            address: 'USA',
        },
        {
            name: 'A. Fergurson',
            description: 'They were always careful with our luggage.',
            address: 'USA',
        },
        {
            name: 'Frank W.',
            description: 'Their staff was always in great mood, they love to converse and suggest places to visit.',
            address: 'USA',
        },
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    return (
        <section id="testimonial" className="testimonials pt-70 pb-70">
            <div className="container mt-5">
                <h4 className="miniTitle text-center">TESTIMONIALS</h4>
                <div className="text-center ">
                    <h3 className="sectionTitle">What Our Clients are Saying?</h3>
                </div>            
                <div className="row">
                    <div className="col-md-12">
                        <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div class="item">
                                        <div class="shadow-effect">
                                            {/* <img class="img-circle" src={userPic} /> */}

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                        <div class="testimonial-name">
                                            <h5>Rajon Rony</h5>
                                            <small>ITALY</small>
                                        </div>
                                    </div> :
                                    testiMonials.map(testiMonialDetail => {
                                        return (
                                            <TestiMonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />

                                        )
                                    })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestiMonials;