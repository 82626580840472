import React, { useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux'
import { Row, Col, Container } from "react-bootstrap"
import GeneralServicesProjects from '../components/GeneralServicesProjects'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {listProductsProjects,getProjectsInfo} from '../actions/productActions'
import "../css/style.css"
import { useMediaQuery } from 'react-responsive'
import './css/screensStyling.css'

const GeneralHomeScreenProjects = ({match}) => {
  const dispatch=useDispatch()
  
  const productListProjects=useSelector(state=>state.productListProjects)
  const {loading,error,productsProjects} =productListProjects

  const projectListInfo=useSelector(state=>state.projectListInfo)
  const {loadingInfo,errorInfo,projectsInfo} =projectListInfo

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })  


  useEffect(() => {

    dispatch(listProductsProjects(match.params.projectId))
    dispatch(getProjectsInfo(match.params.projectId))
    
     }, [dispatch,match])
  

  return (
    <>
   <Container className="text-center">

    <Row className="text-center">

      <Col>
      {projectsInfo.map((project,index) => (

          <div key={project.id}
          style={ { 
            width:"1067",
          } }
          className="responsive-image">
          <div style={ {
              paddingBottom: ( 613 / 1067 * 100 ) + '%'
            } } />
          <img    
          src={project.image} alt={project.name}
            className="responsive-image__image" />
          </div> 
          
          ))}    

      </Col>

    </Row>
    
    <Row className="text-center">

      <Col>
      {projectsInfo.map((project,index) => (

        <div key={project.id}>
          {isDesktopOrLaptop && <h2>{project.name}</h2>}
          {isBigScreen && <h1>{project.name}</h1>}
          {isTabletOrMobile && <h6>{project.name}</h6>}

          {isDesktopOrLaptop && <h1 class="screensH1">Please Select Your Transportation:</h1>}
          {isBigScreen && <h1 class="screensH1">Please Select Your Transportation:</h1>}
          {isTabletOrMobile && <h5 class="screensH1">Please Select Your Transportation:</h5>}
    
        </div>
       ))}    
      </Col>

    </Row>

  </Container>

     
      {loading?<Loader/>:error?<Message variant='danger'>{error}</Message>: 
      <Row  className='justify-content-md-center'>        
          {productsProjects.map((product,index) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={4}>
                  <GeneralServicesProjects product={product} projectId={match.params.projectId} />                  
              </Col>
          ))}    
         
      </Row>
      }     
    </>
  )
}

export default GeneralHomeScreenProjects
