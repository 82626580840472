import React from "react"
import { Container } from "react-bootstrap"
import './css/componentsStyling.css'

const FooterWix = () => {
  return (
    <Container id='footerWix' fluid>
      <div class='container'>
        <div class='row no-gutters'>
          <div class='col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-4'>
            <p class="footerParagraph">
              <i class='fa fa-location-arrow'></i> Cabo San Lucas, B.C.S, México{" "}
            </p>
            <p class="footerParagraph">
              <i class='fa fa-phone'></i> Calling From The U.S.: +52 624
              130.3666
            </p>
            <p class="footerParagraph">
              <i class='fa fa-phone'></i> Cabo Local: 624 130.3666
            </p>
            <p class="footerParagraph">
              <i class='fa fa-phone'></i> Cabo Local Line 2: 624 130.3644
            </p>
            <p class="footerParagraph">
              <i class='fab fa-facebook-f'></i> Facebook:{" "}
              <a
                style={{ color: "white" }}
                href='http://www.facebook.com/cabotravelsolutions'
                target='blank'
              >
                facebook.com/cabotravelsolutions
              </a>{" "}
            </p>
            <p class="footerParagraph">
              <i class='fa fa-location-arrow'></i> Office: Our vehicles are
              located at Los Cabos International airport
            </p>
          </div>
          <div class='col-xs-12 col-sm-8 col-md-6 col-lg-1 col-xl-1'>
            <p class="footerParagraph">
              <a
                style={{ color: "inherit" }}
                href='https://wparedes9.wixsite.com/my-site-3/copy-of-our-vehicles'
              >
                Our Vehicles
              </a>
            </p>
            <p class="footerParagraph">
              <a
                style={{ color: "inherit" }}
                href='https://wparedes9.wixsite.com/my-site-3/services'
              >
                Our Services
              </a>
            </p>
          </div>
          <div class='col-xs-12 col-sm-8 col-lg-5 col-xl-5 align-self-end'>
            <p class="footerParagraph">
              Copyright @{new Date().getFullYear()} | Designed by Cabo Travel
              Solutions
            </p>
          </div>
          <div class='col-xs-12 col-sm-8 col-md-6 col-lg-1 col-xl-1 align-self-center'>
            <img
              style={{
                width: "159px",
                height: "65px",
              }}
              src={"/images/logo_cts_fondo_transparente.png"}
              alt='logo'
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default FooterWix
