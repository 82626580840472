import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "react-bootstrap"
import GeneralSubActivities from "../components/GeneralSubActivities"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { listSubActivities } from "../actions/productActions"
import "./css/screensStyling.css"

const GeneralHomeScreenSubActivities = ({ match }) => {
  const dispatch = useDispatch()

  const subactivitiesList = useSelector((state) => state.subactivitiesList)

  const { loading, error, productsSubActivities } = subactivitiesList

  useEffect(() => {
    dispatch(listSubActivities(match.params.activityId))
  }, [dispatch, match])

  return (
    <>
      <h1 class='screensH1'>Sub Activities</h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          {productsSubActivities.map((subActivity, index) => (
            <Col key={subActivity.id} sm={12} md={6} lg={4} xl={4}>
              <GeneralSubActivities subActivity={subActivity} subActivityId={match.params.activityId} />
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default GeneralHomeScreenSubActivities
