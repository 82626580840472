import React, { useEffect } from "react"
import HotelItineraries from "./HotelItineraries"
import axios from "axios"

const GeneralHotelsItineraries = ({
  hotelId,
  render,
  setIsHotelItineraries,
  isHotelItineraries,
}) => {
  const server = "https://transportation-backend-test.link"

  const fetchHotelItineraries = async () => {
    const { data } = await axios.get(
      `${server}/services/hotel-itineraries/${hotelId}`
    )

    if (data[0].Itinerary === null) {
      setIsHotelItineraries("")
    } else {
      setIsHotelItineraries(data[0].Itinerary)
    }
  }

  useEffect(() => {
    fetchHotelItineraries()
  }, [render])

  return (
    <div>
      {isHotelItineraries && (
        <HotelItineraries isHotelItineraries={isHotelItineraries} />
      )}
    </div>
  )
}

export default GeneralHotelsItineraries
