import React from "react"
import { Row, Col,Image } from "react-bootstrap"

const LocationScreen = () => {
  return (
    <>

      <Row className='justify-content-md-center'>
          <Col sm={12} md={12} lg={8} xl={8}>
            <p style={{  fontSize: 30,textAlign: "center", paddingTop: "10px" }}>Our Vehicles Location at the Airport:</p>
            <p style={{  fontSize: 20,textAlign: "justify", paddingTop: "10px" }}>Our vehicles are located right at the entrance of: Los Cabos International Airport, B.C.S. MEXICO.</p>
            <Image src="./images/cabo_seasons_location.jpg" fluid />                
          </Col>
      </Row>
      
    </>
  )
}

export default LocationScreen
