import React from "react"
import TestiMonials from "../components/TestiMonials/TestiMonials";

const TestimonialsScreen = () => {

  return (
    <>
       <TestiMonials></TestiMonials>
    </>
  )
}

export default TestimonialsScreen
