import React from "react"
import { Card } from "react-bootstrap"

//const GeneralRates = ({ hotelRates, value, onChange }) => {
const HotelItineraries = ({isHotelItineraries}) => {

  
  return (
    <div>
      
    <Card.Text as='h6'><strong>Itineraries</strong></Card.Text>
    <Card className="text-center">
      <Card.Body>
        <section dangerouslySetInnerHTML={{ __html:isHotelItineraries }} />
      </Card.Body>
    </Card>
      
    </div>
  )
}

export default HotelItineraries
