import React, { useState, useEffect } from "react"
import { Radio } from "antd"
import { Link } from "react-router-dom"
import { Card, Button } from "react-bootstrap"
import axios from "axios"

const GeneralSubActivities = ({ subActivity, subActivityId }) => {
  const [subActivityPrice, setSubActivityPrice] = useState(0)

  const server = "https://transportation-backend-test.link"

  const fetchSubActivityPrices = async () => {
    const { data } = await axios.get(
      `${server}/services/get-prices-activities/${subActivityId}`
    )
    if (data.length) {
      setSubActivityPrice(data[0].price)
    }
  }

  useEffect(() => {
    fetchSubActivityPrices(subActivityId)
  }, [])

  return (
    <div>
      <Card className='text-center'>
        <Card.Img
          src={subActivity.image}
          variant='top'
          width='418px'
          height='279px'
        />
        <Card.Body>
          <Card.Title as='h4'>
            <strong>{subActivity.name}</strong>
          </Card.Title>
          <Card.Text as='h5'>${subActivityPrice}</Card.Text>
          <Radio.Group buttonStyle='solid'>
            <Card.Text as='h6'></Card.Text>
            <Link
              to={{
                pathname: `/service/${subActivity.id}`,
                state: {
                  subActivityPrice: subActivityPrice,
                },
              }}
            >
              <Button variant='outline-dark' size='md'>
                Buy
              </Button>
            </Link>
          </Radio.Group>
        </Card.Body>
      </Card>
    </div>
  )
}

export default GeneralSubActivities
