import React from "react"
import { useLocation } from  'react-router-dom'
import { Navbar, Nav,NavDropdown } from "react-bootstrap"
import './css/componentsStyling.css'

const Header = () => {
//assigning location variable
const location = useLocation();

//destructuring pathname from location
const { pathname } = location;

//Javascript split method to get the name of the path in array
const splitLocation = pathname.split("/");  return (
    <header>
<Navbar collapseOnSelect expand="lg">
  <Navbar.Brand href="https://wparedes9.wixsite.com/my-site-3">
  <img
        src="/logo_cts_fondo_transparente.png"
        width="159"
        height="65"
        className="d-inline-block align-top"
        alt="Cabo Travel Solutions Logo"
      />
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">   
    <Nav className="mr-auto justify-content-end" style={{ flex: 1}} >
    <Nav.Link className="navLink" href="https://wparedes9.wixsite.com/my-site-3">Home</Nav.Link>
      <Nav.Link className="navLink" href="https://wparedes9.wixsite.com/my-site-3/about-us">About Us</Nav.Link>
      <NavDropdown className="navLink" title="Services" id="basic-nav-dropdown">
           <NavDropdown.Item href="https://wparedes9.wixsite.com/my-site-3/copy-of-our-vehicles-1">Destinations</NavDropdown.Item>
           <NavDropdown.Item href="https://wparedes9.wixsite.com/my-site-3/copy-of-our-vehicles">Our Vehichles</NavDropdown.Item>
      </NavDropdown>
      <Nav.Link className="navLink" href="https://www.reserve-cabotravelsolutions.com/transportations">Reserve</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>    
</header>
  )
}

export default Header
