import React, { useState, useEffect } from "react"
import { Card, Form } from "react-bootstrap"
import axios from "axios"
//import {useDispatch,useSelector} from 'react-redux'

const Airlines = ({ value, onChange }) => {
  const [airlines, setAirlines] = useState([])
  const server = "https://transportation-backend-test.link"

  const fetchAirlines = async () => {
    const { data } = await axios.get(`${server}/airlines`)
    data.unshift(" ")
    setAirlines(data)
  }

  useEffect(() => {
    fetchAirlines()
  }, [])

  return (
    <div>
      <Card className='text-center'>
        <Card.Body>
          <Card.Title as='h6'>Arrival Airline</Card.Title>
          <Form.Control as='select' required value={value} onChange={onChange}>
            {airlines.map((x, index) => (
              <option key={index} value={x.id}>
                {x.name}
              </option>
            ))}
          </Form.Control>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Airlines
